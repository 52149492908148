import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButtonPS = styled.button`
  padding: 10px;
  border-radius: 30px;
  border: none;
  background-image: linear-gradient(120deg, rgba(253, 112, 180, 0.6) 16%, rgba(180, 77, 255, 0.8) 52%, rgba(35, 102, 247, 0.6) 100%, rgba(247, 166, 12, 0.6));
  font-family: DM+Serif+Display;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  width: 240px;
  cursor: pointer;
  box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  transition: .3s;
  :hover{
    transform: translate3d(0, 4px, 0);
    box-shadow: none;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonPub = styled.button`
  padding: 10px;
  margin-top:12px;
  border-radius: 30px;
  border: none;
  background-image: linear-gradient(90deg, rgba(115, 235, 248, 1), rgba(150, 167, 241, 1));
  font-family: DM+Serif+Display;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  width: 240px;
  cursor: pointer;
  box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  transition: .3s;
  :hover{
    transform: translate3d(0, 4px, 0);
    box-shadow: none;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonPSclimed = styled.button`
  padding: 10px;
  border-radius: 30px;
  border: none;
  background-image: linear-gradient(120deg, rgba(140, 140, 140, 0.8) 16%, rgba(110, 110, 110, 0.8) 52%, rgba(80, 80, 80, 0.8));
  font-family: DM+Serif+Display;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  width: 240px;
  cursor: pointer;
  box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  transition: .3s;
  :hover{
    transform: translate3d(0, 4px, 0);
    box-shadow: none;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  @media screen and (max-width: 360px) {
    .logo {
      max-width: 240px;
      margin-top: 40px;
    }
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const ulB = styled.ul`
ul {
  list-style: none;
}
`;

export const ilB = styled.li`
li {
  margin-left: 2rem;
  text-indent: -1.2rem;
}
li:before {
  content: "※";
}
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [totalSupply, setTotalSupply] = useState(0);
  const [mintLimit, setMintLimit] = useState(0);
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimed, setClaimed] = useState(0);
  const [feedback, setFeedback] = useState(``);
  const [pubSaleStart, setPubSaleStart] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const bgstyles = {
    background: 'linear-gradient(-45deg, rgba(255, 78, 208, 0.6), rgba(168, 194, 238, 0.6), rgba(194, 249, 208, 0.6), rgba(255, 222, 165, 0.6)) fixed',
    backgroundSize: '800% 800%',
    animation: 'GradietionAnimation 9s ease infinite',
  };
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    WEI_COST_PUBLIC: 0,
    DISPLAY_COST: 0,
    DISPLAY_COST_PUBLIC: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkPubSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .pubSaleStart()
      .call()
      .then((receipt) => {
        setPubSaleStart(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply()
      .call()
      .then((receipt) => {
        setTotalSupply(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkMintLimit = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit()
      .call()
      .then((receipt) => {
        setMintLimit(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkClaimed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .claimed(blockchain.account)
      .call()
      .then((receipt) => {
        setClaimed(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST_PUBLIC;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * 1);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`購入手続き中...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .pubMint(1)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("失敗したので再度試してみてください");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `購入が完了しました`
        );
        setClaimingNft(false);
        checkClaimed();
        checkTotalSupply();
        dispatch(fetchData(blockchain.account));
      });
  };

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkMintLimit();
    checkClaimed();
    checkPubSaleStart();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkMintLimit();
    checkClaimed();
    checkPubSaleStart();
  }, [blockchain.account]);

  const [showCreditCardSection, setShowCreditCardSection] = useState(false);

  const handleButtonClick = () => {
    setShowCreditCardSection(true);
  };

  const handleGoBack = () => {
    setShowCreditCardSection(false);
  };

  const renderMainContent = () => {
    if (showCreditCardSection) {
      
      return (

      <s.Screen>
      
      <s.Container
        flex={1}
        ai={"center"}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
        style={{backgroundSize: "cover",backgroundRepeat: "no-repeat"}}
      >
      <s.Container
        flex={1}
        ai={"center"}
        style={bgstyles}
      >
      <s.TextDescription style={{ position: 'fixed', width: '100%', fontSize: 18, padding: "0px 5px", textAlign: "left", color: "#fff", backgroundColor: "rgba(0, 0, 0)", zIndex: 2}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledLink style={{ color: "#fff", margin: "0px 0px 0px 32px" }} href="">
            <img style={{ width: "50px", marginTop: "10px", marginRight: "12px" }} src="/config/images/logo_mokumoku.png" alt="MOKUMOKU LOGO"></img>
          </StyledLink>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: "32px" }}>
            <a href="https://opensea.io/collection/mokumoku-nft" target="_blank" rel="noopener" style={{ marginRight: "16px" }}>
              <img style={{ width: "30px",marginTop:"10px" }} src="/config/images/icon_opensea.png" alt="OpenSea Icon"></img>
            </a>
            <a href="https://twitter.com/MOKUMOKU_NFT" target="_blank" rel="noopener" style={{ marginRight: "16px" }}>
              <img style={{ width: "30px",marginTop:"10px" }} src="/config/images/icon_twitter.png" alt="Twitter Icon"></img>
            </a>
            <a href="https://discord.com/invite/vcEepXy7gm" target="_blank" rel="noopener">
              <img style={{ width: "30px",marginTop:"10px" }} src="/config/images/icon_discord.png" alt="Discord Icon"></img>
            </a>
          </div>
        </div>
      </s.TextDescription>
      
      <s.Container ai={"center"} jc={"center"} fd={"column"} style={{ position: "relative", marginTop: "80px" }}>
        <ResponsiveWrapper flex={1} style={{ padding: "20px 20px 24px 20px", maxWidth: "640px", position: "relative", zIndex: 1 }}>
        <s.Container
          flex={1}
          ai={"center"}
          style={{marginTop:"54px",padding: "18px 8px",borderRadius: 12, backgroundColor: "rgba(0, 0, 0, 0.6)"}}
        >

          <s.TextTitle
            style={{
              padding: "12px 8px 4px 8px",
              textAlign: "center",
              fontSize: 26,
              color: "var(--accent-text)",
              fontWeight: "bold",
            }}
          >
            ~ ご購入時の注意事項 ~
          </s.TextTitle>
          <s.TextDescription
            style={{
              fontSize: 19,
              color: "var(--accent-text)",
              fontWeight: "bold",
            }}
          >
            <ul style={{listStyle: "inside",marginTop:"16px"}}>
              <li style={{padding: "0px 46px",textIndent: "-1.7rem"}}>事前にMetaMaskのウォレットアドレスをご準備ください</li>
            </ul>
          </s.TextDescription>
          <s.Container
            flex={1}
            ai={"center"}
            style={{padding: "0px 5px 5px 5px", backgroundColor: "rgba(200, 200, 0, 0)"}}
          >
            <s.TextDescription
              style={{fontSize:"15px",padding:"10px 8px",color: "var(--accent-text)"}}
            >
              ご購入の際、MetaMaskのウォレットアドレスを入力いただく必要がございます。NFTを初めて購入する方は<a href="https://drive.google.com/file/d/1LEhsS7JAuRxKD5oE1DyhgblzCKO0gLqQ/view"  target="_blank" rel="noopener" style={{fontWeight:"bold",color:"#87cefe"}}>こちら</a>にMetaMaskの作成方法をまとめておりますのでご確認ください。
            </s.TextDescription>
            <s.TextDescription
              style={{
                fontSize: 19,

                color: "var(--accent-text)",
                fontWeight: "bold",
              }}
            >
                <ul style={{listStyle: "inside",marginTop:"16px"}}>
                  <li style={{padding: "0px 46px",textIndent: "-1.7rem"}}>購入時点のETH/日本円レートでクレジットカードへの請求をいたします</li>
                </ul>
            </s.TextDescription>
            <s.TextDescription
              style={{fontSize:"15px",padding:"10px 8px",color: "var(--accent-text)"}}
            >
              「MOKUMOKU GENESIS VIP PASS」のETH価格を購入時点の日本円レートで換算した金額にネットワーク手数料を追加した金額が請求されます。
            </s.TextDescription>
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)", fontWeight:"bold"}}
            >
              <s.SpacerXSmall/>
              10月31日20:00 ~ 11月30日23:59
            </s.TextDescription>
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)", marginBottom: "0px", fontWeight:"bold"}}
            >
              Price:{CONFIG.DISPLAY_COST_PUBLIC}ETH
            </s.TextDescription>
            <a href="https://paypiement.xyz/projects/d6ed043e-0800-4573-b485-4327aea256e6?quantity=1" target="_blank" rel="noopener">
              <StyledButtonPub style={{marginTop:"16px"}}>
                クレジットカード決済へ進む
              </StyledButtonPub>
            </a>
            <s.SpacerXSmall />
            <p style={{color:"rgba(255,255,255,1)",fontSize:"12px"}} onClick={handleGoBack}>元の画面に戻る</p>
          </s.Container>
        </s.Container>
        </ResponsiveWrapper>
        <img
          className="logo"
          style={{ maxWidth: "55%", width: "320px",marginBottom: "80px", position: "absolute", top: 0, left: "50%", transform: "translateX(-50%)", zIndex: 0 }}
          src="/config/images/logo_mokumoku_shadow.png"
          alt="Logo"
        />
      </s.Container>

        <s.SpacerMedium />
      
      </s.Container>
      </s.Container>
    </s.Screen>


      );
    } else {
      return (


        
        <s.Screen>
      
      <s.Container
        flex={1}
        ai={"center"}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
        style={{backgroundSize: "cover",backgroundRepeat: "no-repeat"}}
      >
      <s.Container
        flex={1}
        ai={"center"}
        style={bgstyles}
      >
      <s.TextDescription style={{ position: 'fixed', width: '100%', fontSize: 18, padding: "0px 5px", textAlign: "left", color: "#fff", backgroundColor: "rgba(0, 0, 0)"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledLink style={{ color: "#fff", margin: "0px 0px 0px 32px" }} href="">
            <img style={{ width: "50px", marginTop: "10px", marginRight: "12px" }} src="/config/images/logo_mokumoku.png" alt="MOKUMOKU LOGO"></img>
          </StyledLink>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: "32px" }}>
            <a href="https://opensea.io/collection/mokumoku-nft" target="_blank" rel="noopener" style={{ marginRight: "16px" }}>
              <img style={{ width: "30px",marginTop:"10px" }} src="/config/images/icon_opensea.png" alt="OpenSea Icon"></img>
            </a>
            <a href="https://twitter.com/MOKUMOKU_NFT" target="_blank" rel="noopener" style={{ marginRight: "16px" }}>
            <img style={{ width: "26px",marginTop:"10px" }} src="/config/images/logo-white.png" alt="Twitter Icon"></img>
            </a>
            <a href="https://discord.com/invite/vcEepXy7gm" target="_blank" rel="noopener">
              <img style={{ width: "30px",marginTop:"10px" }} src="/config/images/icon_discord.png" alt="Discord Icon"></img>
            </a>
          </div>
        </div>
      </s.TextDescription>
      
      <s.Container ai={"center"} jc={"center"} fd={"row"} style={{flexWrap: "wrap"}}>
          <img className="logo"
            style={{maxWidth: "55%", width: "320px", marginTop: "80px"}}
            src="/config/images/logo_mokumoku_shadow.png"
            alt="Logo"
          />
      </s.Container>

      
      
        <ResponsiveWrapper flex={1} style={{ padding: "40px 20px 24px 20px", maxWidth:"640px",maxHeight: "460px"}}>

        <s.Container
                flex={1}
                ai={"center"}
                style={{padding: "18px 0px",borderRadius: 12, backgroundColor: "rgba(0, 0, 0, 0.6)"}}
        >
          
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <s.TextTitle
                  style={{
                    padding: "0px 8px 0px 8px",
                    textAlign: "center",
                    fontSize: 28,
                    color: "var(--accent-text)",
                    fontWeight: "bold",
                  }}
                >
                  MOKUMOKU
                </s.TextTitle>
                <s.TextTitle
                  style={{
                    padding: "0px 8px 0px 8px",
                    textAlign: "center",
                    fontSize: 28,
                    color: "var(--accent-text)",
                    fontWeight: "bold",
                  }}
                >
                  GENESIS VIP PASS
                </s.TextTitle>
              </div>
            
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "var(--accent-text)",
                fontWeight: "bold",
              }}
            >
              {totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>

              <s.Container
                flex={1}
                ai={"center"}
                style={{padding: 5, backgroundColor: "rgba(200, 200, 0, 0)", maxHeight: "155px"}}
              >
                <s.TextTitle
                  style={{fontSize: 30,textAlign: "center", color: "var(--accent-text)", fontWeight: "bold"}}
                >Public Sale
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)"}}
                >
                  ※どなたでも購入可能
                </s.TextDescription>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontWeight:"bold"}}
                >
                <s.SpacerXSmall/>
                  10月31日20:00 ~ 11月30日23:59
                </s.TextDescription>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", marginBottom: "0px", fontWeight:"bold"}}
                >
                  Price:{CONFIG.DISPLAY_COST_PUBLIC}ETH
                </s.TextDescription>
              </s.Container>
              
        {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)"}}
                >
                  完売しました！
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  →{CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButtonPS
                      onClick={(e) => {
                        e.stopPropagation(); // クリックイベントのバブリングを停止
                        dispatch(connect());
                        getData();
                      }}
                    >
                      Connect Wallet
                    </StyledButtonPS>
                    
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    
                    {/* pabSaleここから */}
                    {pubSaleStart == true ? (//AL確認
                        <>
                        {claimed > mintLimit - 1 ? (
                          <>

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>

                          <StyledButtonPSclimed
                            disabled={1}//claimingAlNftがtrueなら disabledを表示させる。＝クリックできない
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {"購入済み" }
                          </StyledButtonPSclimed>
                          </s.Container>
                          <s.SpacerSmall/>
                          </>
                        ) : (
                          <>

                            
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButtonPS
                                disabled={claimingNft ? 1 : 0}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                              {claimingNft ? "購入中..." : "Ethereumで購入する"}
                              </StyledButtonPS>
                            </s.Container>
                            <s.SpacerMedium/>
                            {claimed > 0 ? (
                                <>
                                </>                              
                            ):(
                            <></>
                            )}
                          </>
                        )}
                        </>
                      ) : (
                        <>
                        
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <s.TextSubTitle
                          style={{
                            color: "var(--accent-text)", fontFamily: "DM+Serif+Display"
                          }}
                        >
                        <s.SpacerSmall/>
                          {"セール開始をお待ちください"}
                        <s.SpacerXSmall/>
                        </s.TextSubTitle>
                        </s.Container>

                        </>
                      )}
                      {/* pubSaleここまで */}
                  </> 
                )}
              </>
            )}





      <StyledButtonPub onClick={handleButtonClick}>
                      クレジットカードで購入する
                    </StyledButtonPub>
                    <s.SpacerXSmall />
      {showSection && <div></div>}


          </s.Container>
        
        </ResponsiveWrapper>
  
        
        <s.SpacerMedium />
      
      </s.Container>
      </s.Container>
    </s.Screen>



      );
    }
  };

  return (
    <s.Screen
    >
      <s.Container
        flex={1}

      >
        {/* Header */}
        <s.TextDescription>
          {/* Header content */}
        </s.TextDescription>
  
        {/* Main content */}
        {renderMainContent()}
  
      </s.Container>
    </s.Screen>
  );

  }

export default App;